'use client';

import { Button } from '@mantine/core';
import { signIn, useSession } from 'next-auth/react';
import Link from 'next/link';

export default function CTA() {
  const { data: session, status } = useSession();

  return session ? (
    <Button
      size='md'
      component={Link}
      href='/ascents'
      loading={status === 'loading'}
    >
      Your Feed
    </Button>
  ) : (
    <Button size='md' onClick={() => signIn()} loading={status === 'loading'}>
      Get started
    </Button>
  );
}
